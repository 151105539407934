import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import stylesSession from '../Sessions.module.scss';
import styles from './EditUser.module.scss';
import useFetch from '../../../../Hooks/useFetch';
import useForm from '../../../../Hooks/useForm';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Error from '../../../../components/Helper/Error';
import { Modal } from './Modal';
import { ADMIN_GET_DATA_USER } from '../../../../api';

const EditUser = () => {
  const { loading, error, setError, request, data } = useFetch();
  const [modalAtivo, setModalAtivo] = useState(false);
  const [message, setMessage] = useState(false);
  const modalRef = useRef();
  const name = useForm();
  const inputFocus = useRef(null);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 2700);
    return () => clearInterval(intervalo);
  }, [message]);

  async function handleSubmit(event) {
    event.preventDefault();
    if (name.value) {
      const token = localStorage.getItem('token');
      const body = { userName: name.value };
      const { url, options } = ADMIN_GET_DATA_USER(body, token);
      const response = await request(url, options);
      if (response.response.ok) {
        setModalAtivo(true);
      }
    } else if (name.value === '') setError('Preencha um valor.');
  }

  return (
    <section className={stylesSession.containerPai}>
      <section
        id="modal"
        onClick={(event) =>
          event.target.id === 'modal' ? setModalAtivo(false) : ''
        }
      >
        <div
          id="modal"
          ref={modalRef}
          className={classNames({
            [styles.modalContainer]: modalAtivo === false,
            [styles['modalAtivo']]: modalAtivo === true,
          })}
        >
          <div className={styles.wrapperModal}>
            <Modal
              name={name}
              inputFocus={inputFocus}
              loading={loading}
              onClose={() => setModalAtivo(false)}
              data={data}
              setMessage={setMessage}
              ativo={modalAtivo}
            />
          </div>
        </div>
      </section>

      <div className={stylesSession.container}>
        {message && (
          <section className={styles.messageContainer}>
            <p>Usuário editado com sucesso!</p>
          </section>
        )}
        <h1 className={styles.title}>Edit User</h1>
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div className={styles.ContainerForm}>
            <Input
              useRef={inputFocus}
              label={'Username'}
              type="text"
              name="username"
              {...name}
            />
            {loading ? (
              <Button loading={true} disabled>
                Checking
              </Button>
            ) : (
              <Button>Edit user</Button>
            )}
            {error && <Error error={error} />}
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditUser;
