import Head from '../../components/Helper/Head';
import SideBar from '../../components/SideBar/UserSidebar';
import { Route, Routes } from 'react-router-dom';
import MyProfile from './Sessions/MyProfile';
import styles from './User.module.scss';
import MyCompany from './Sessions/MyCompany';
import VeryfiEndpoints from './Sessions/VeryfiEndpoints';
import Historic from './Sessions/Historic';
import SupportDocuments from './Sessions/SupportDocuments';
import Signature from './Sessions/Signature';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Administration from './Sessions/Administration';
import Help from './Sessions/Help';

const User = () => {
  const [sideOpen, setSideOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (width <= 952) setSideOpen(false);
      else if (sideOpen === true) setSideOpen(true);
      // else
    };
  }, [window.innerWidth]);

  return (
    <section className={styles.containerPai}>
      <Head
        title="Home Page"
        description="Página da conta do usário do site Hellow Data"
      />
      <div className={styles.wrapperContainer}>
        <div className={styles.wrapper}>
          <div
            className={classNames({
              [styles.sidebar]: sideOpen === true,
              [styles.sidebarSmall]: sideOpen === false,
            })}
          >
            <SideBar sideOpen={sideOpen} setSideOpen={setSideOpen} />
          </div>
          <div className={styles.routes}>
            <Routes>
              <Route path="/myprofile" element={<MyProfile />} />
              <Route path="/mycompany" element={<MyCompany />} />
              <Route path="/veryfyendpoints" element={<VeryfiEndpoints />} />
              <Route path="/historic" element={<Historic />} />
              <Route
                path="/supportingdocumentions"
                element={<SupportDocuments />}
              />
              <Route path="/signature" element={<Signature />} />
              <Route path="/administratioN" element={<Administration />} />
              <Route path="/help" element={<Help />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default User;
