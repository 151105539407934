import { useState } from 'react';

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Preencha um email válido',
  },
  name: {
    regex: /^[a-zA-Z]*$/,
    message: 'Não é permitido acentuações, números ou pontuações!',
  },
  username: {
    regex: /^[a-zA-Z0-9]*$/,
    message: 'Não é permitido acentuações ou pontuações!',
  },
  password: {
    regex: /[a-z][a-zA-Z0-9]{6}/,
    // regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
    message: 'A senha tem que ter no mínimo 6 caracteres!',
    // 'A senha precisa ter 1 caracter maíusculo, 1 minúsculo e 1 digito. Com no mínimo 8 caracteres.',
  },
};

const useForm = (type) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  function validate(value) {
    if (type === null) return true;
    if (value.length === 0) {
      setError('Preencha um valor');
      return null;
    } else if (types[type] && !types[type].regex.test(value)) {
      setError(types[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  }

  function onChange({ target }) {
    if (error) validate(target.value);
    setValue(target.value);
  }

  return {
    value,
    setValue,
    onChange,
    error,
    setError,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
