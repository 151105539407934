import styles from '../Sessions.module.scss';

const SupportDocuments = () => {
  return (
    <section className={styles.containerPai}>
      {' '}
      <div className={styles.container}>SupportDocuments</div>
    </section>
  );
};

export default SupportDocuments;
