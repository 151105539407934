import { useEffect, useState } from 'react';
import Button from '../../../../../components/Forms/Button';
import Input from '../../../../../components/Forms/Input';
import styles from './Modal.module.scss';
import { IoClose } from 'react-icons/io5';
import useForm from '../../../../../Hooks/useForm';
import useFetch from '../../../../../Hooks/useFetch';
import { ADMIN_EDIT_USER_PUT } from '../../../../../api';
import InvalidPhoto from '../../../../../assets/invalidPhoto.svg';
import Error from '../../../../../components/Helper/Error';

export const Modal = ({
  onClose,
  setMessage,
  data,
  ativo,
  inputFocus,
  name,
}) => {
  const email = useForm('email');
  const firstname = useForm('name');
  const lastname = useForm('name');
  const imagem = useForm();
  const { request, loading, error, setError } = useFetch();
  const [errorBoolean, setErrorBoolean] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [photoIsValid, setPhotoIsValid] = useState(false);
  const [urlImage, setUrlImage] = useState();

  useEffect(() => {
    if (isMounted) {
      setError(false);
      setErrorBoolean(false);
      setUrlImage(data.profilePictureUrl);
      setPhotoIsValid(true);
    } else {
      setIsMounted(true);
    }
    if (data) {
      email.setValue(data.email);
      firstname.setValue(data.firstName);
      lastname.setValue(data.lastName);
      imagem.setValue(data.profilePictureUrl);
    }
  }, [ativo]);

  async function handleSubmit(e) {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (
      token === '' ||
      email.error !== null ||
      lastname.error !== null ||
      firstname.error !== null
    ) {
      if (email.error !== null) setError('Preencha um email válido');
      if (firstname.error !== null) setError('Preencha um Firstname válido!');
      if (lastname.error !== null) setError('Preencha um Lastname válido!');
      if (token === '') setError('Token do ADM inválido, faça o logout e log novamente!');
    } else {
      const body = {
        userName: data.userName,
        email: email.value ? email.value : data.email,
        firstName: firstname.value ? firstname.value : data.firstName,
        lastName: lastname.value ? lastname.value : data.lastName,
        profilePictureUrl: urlImage ? urlImage : imagem.value,
        emailConfirmed: true,
      };
      const { url, options } = ADMIN_EDIT_USER_PUT(body, token);
      const response = await request(url, options);
      if (response.response.ok) {
        setMessage(true);
        onClose();
        inputFocus.current.focus();
        name.setValue('');
      }
    }
  }

  // toda vez que a url da imagem mudar ele aciona
  async function handleChange(e) {
    const url = e.target.value;
    try {
      if (!url.includes('https://')) throw new Error('Url inválida');
      const response = await fetch(url);
      if (!response.ok) throw new Error('Url inválida');
      setPhotoIsValid(true);
      setErrorBoolean(false);
    } catch (error) {
      setErrorBoolean(true);
      setPhotoIsValid(false);
      setErrorBoolean(true);
    }
  }

  return (
    <section className={styles.modalContainer}>
      <IoClose onClick={onClose} className={styles.fechar} />
      <div className={styles.modal}>
        <form onSubmit={handleSubmit} className={styles.containerForm}>
          <div className={styles.photoConteiner}>
            <div className={styles.photo}>
              <img
                src={photoIsValid ? urlImage : InvalidPhoto}
                className={styles.imagem}
                alt="User photo"
              />
            </div>
          </div>

          <div className={styles.editPhoto}>
            <div className={styles.inputUrl}>
              <label htmlFor="URL">URL image</label>
              <input
                type="text"
                name="URL"
                className={styles.input}
                value={urlImage}
                onChange={(e) => {
                  setUrlImage(e.target.value);
                  handleChange(e);
                }}
              />
            </div>
            <Input label={'Email'} type="email" name="email" {...email} />
            <Input
              label={'Firstname'}
              type="text"
              name="firstname"
              {...firstname}
            />
            <Input
              label={'Lastname'}
              type="text"
              name="lastname"
              {...lastname}
            />
          </div>

          <div>
            {errorBoolean ? (
              <Button disabled cursor={'not-allowed'}>
                Update
              </Button>
            ) : loading ? (
              <Button loading={true} cursor={'pointer'}>
                Updating
              </Button>
            ) : (
              <Button cursor={'pointer'}>Update</Button>
            )}
            {error && <Error error={error} />}
          </div>
        </form>
      </div>
    </section>
  );
};
