import { useState, createContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_LOGIN_POST, USER_GET } from './api';

export const UserContext = createContext();

export const UserStorage = ({ children }) => {
  const [data, setData] = useState(null);
  const [login, setLogin] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  // função de logout
  const userLogOut = useCallback(async function () {
    setData(null);
    setError(null);
    setLoading(false);
    setLogin(false);
    setAdmin(false);
    window.localStorage.removeItem('token');
    navigate('/login');
  }, []);

  // função que apenas irá setar
  const getUser = async (token) => {
    const { url, options } = USER_GET(token);
    const response = await fetch(url, options);
    const json = await response.json();
    setData(json);
    if (json.userName === 'admin') {
      setLogin(false);
      setAdmin(true);
      navigate('/administration/activeuser');
    } else {
      setAdmin(false);
      setLogin(true);
      navigate('/count/myprofile');
    }
  };

  //  função que faz login e direciona para a conta
  const userLogin = async (username, password) => {
    try {
      setError(null);
      setLoading(true);
      const { url, options } = USER_LOGIN_POST({ username, password });
      const response = await fetch(url, options);
      if (response.status === 401) throw new Error('Senha inválida!');
      if (!response.ok) throw new Error('Usuário inválido');
      const json = await response.json();
      const token = json.token;
      window.localStorage.setItem('token', token);
      await getUser(token);
      if (json.primeiroNome === 'admin') {
        setLogin(false);
        setAdmin(true);
        navigate('/administration/activeuser');
      } else if (json.primeiroNome === 'kaualus') {
        setAdmin(false);
        setLogin(true);
        navigate('/count/myprofile');
      }
    } catch (err) {
      setError(err.message);
      setAdmin(false);
      setLogin(false);
    } finally {
      setLoading(false);
    }
  };

  // efeito para fazer o acesso do usuário caso tenha um token ativo e válido
  useEffect(() => {
    async function autoLogin() {
      setToken(window.localStorage.getItem('token'));
      if (token) {
        try {
          setError(null);
          setLoading(true);
          const { url, options } = USER_GET(token);
          const response = await fetch(url, options);
          const json = await response.json();
          if (!response.ok) throw new Error('Token inválido');
          await getUser(token);
          if (json.userName === 'admin') {
            setLogin(false);
            setAdmin(true);
            navigate('/administration/activeuser');
          } else {
            setAdmin(false);
            setLogin(true);
            navigate('/count/myprofile');
          }
        } catch (err) {
          userLogOut();
        } finally {
          setLoading(false);
        }
      } else {
        setLogin(false);
        setAdmin(false);
      }
    }
    autoLogin();
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        userLogin,
        userLogOut,
        loading,
        login,
        admin,
        error,
        setError,
        data,
        setData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
