import Head from '../../components/Helper/Head';
import styles from './Admin.module.scss';
import { Route, Routes } from 'react-router-dom';
import EditUser from './Sessions/EditUser';
import DeleteUser from './Sessions/DeleteUser';
import ActiveUser from './Sessions/ActiveUser';
import AdminSidebar from '../../components/SideBar/AdmSideBar';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

const Admin = () => {
  const [sideOpen, setSideOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (width <= 952) setSideOpen(false);
      else if (sideOpen === true) setSideOpen(true);
    };
  }, [window.innerWidth]);

  return (
    <section className={styles.containerPai}>
      <Head
        title="Administration"
        description="Página da administração site Hellow Data"
      />
      <div className={styles.wrapperContainer}>
        <div className={styles.wrapper}>
          <div
            className={classNames({
              [styles.sidebar]: sideOpen === true,
              [styles.sidebarSmall]: sideOpen === false,
            })}
          >
            <AdminSidebar sideOpen={sideOpen} setSideOpen={setSideOpen} />
          </div>
          <div className={styles.routes}>
            <Routes>
              <Route path="/activeuser" element={<ActiveUser />} />
              <Route path="/deleteuser" element={<DeleteUser />} />
              <Route path="/edituser" element={<EditUser />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Admin;
