import { ReactComponent as Logo } from '../../../assets/icons/helloData.svg';
import styles from '../SideBar.module.scss';
import Button from '../ButtonSideBar';
import { FaUserAlt } from 'react-icons/fa';
// import { FaHistory } from 'react-icons/fa';
import { IoBusiness } from 'react-icons/io5';
// import { GoGlobe } from 'react-icons/go';
// import { HiDocumentText } from 'react-icons/hi2';
// import { SiSubstack } from 'react-icons/si';
// import { BsGearFill } from 'react-icons/bs';
import { GoSignOut } from 'react-icons/go';
// import { BiHelpCircle } from 'react-icons/bi';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import classNames from 'classnames';

export const SideBar = ({ sideOpen, setSideOpen }) => {
  return (
    <aside className={styles.sidebar}>
      <div className={styles.wrapper}>
        <div
          className={classNames({
            [styles.logoContainerOpen]: true,
            [styles.logoContainerClose]: sideOpen === false,
          })}
        >
          <div
            className={styles.btnMenu}
            onClick={() => {
              setSideOpen(!sideOpen);
            }}
          >
            <HiOutlineMenuAlt3 size={25} />
          </div>
          <div className={styles.log}>
            <Logo />
            {sideOpen && <p className={styles.title}>HomePage</p>}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.wrapperBtn}>
            <Button
              show={sideOpen}
              btnContainer="buttonContainer"
              btnClass="button"
              iconClass="icon"
              to="/count/myprofile"
              svg={<FaUserAlt />}
            >
              My profile
            </Button>
            <Button
              btnContainer="buttonContainer"
              show={sideOpen}
              btnClass="button"
              iconClass="icon"
              to="/count/mycompany"
              svg={<IoBusiness />}
            >
              My Company
            </Button>
            {/* <Button
              btnContainer="buttonContainer"
              show={sideOpen}
              btnClass="button"
              iconClass="icon"
              to="/count/veryfyendpoints"
              svg={<GoGlobe />}
            >
              Veryfi Endpoinjs
            </Button> */}
            {/* <Button
              btnContainer="buttonContainer"
              show={sideOpen}
              btnClass="button"
              iconClass="icon"
              to="/count/historic"
              svg={<FaHistory />}
            >
              Historic
            </Button> */}
            {/* <Button
              btnContainer="buttonContainer"
              show={sideOpen}
              btnClass="button"
              iconClass="icon"
              to="/count/supportingdocumentions"
              svg={<HiDocumentText />}
            >
              Support docu.
            </Button> */}
            {/* <Button
              btnContainer="buttonContainer"
              show={sideOpen}
              btnClass="button"
              iconClass="icon"
              to="/count/signature"
              svg={<SiSubstack />}
            >
              Signature
            </Button> */}
          </div>
          <div className={styles.settings}>
            {/* <Button
              btnContainer="buttonContainer2"
              show={sideOpen}
              btnClass="button2"
              iconClass="icon2"
              to="/count/administration"
              svg={<BsGearFill />}
            >
              Administration
            </Button> */}
            {/* <Button
              btnContainer="buttonContainer2"
              show={sideOpen}
              btnClass="button2"
              iconClass="icon2"
              to="/count/help"
              svg={<BiHelpCircle />}
            >
              Help
            </Button> */}
            <Button
              btnContainer="buttonContainer2"
              show={sideOpen}
              btnClass="button2"
              iconClass="icon2"
              svg={<GoSignOut />}
            >
              Log out
            </Button>
            <div className={styles.space}></div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
