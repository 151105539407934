/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/InputForm';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';
import useForm from '../../Hooks/useForm';
import UserContext from '../../UserContext';
import Error from '../../components/Helper/Error';
import { NOAUTH_VERIFICA_USER } from '../../api';
import useFetch from '../../Hooks/useFetch';

export default function LoginForm() {
  const username = useForm();
  const password = useForm();
  const { userLogin, error, loading, setError } = useContext(UserContext);
  const { request } = useFetch();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username.validate() && password.validate()) {
      if (validateUser() === true) {
        console.log('true');
      } else {
        userLogin(username.value, password.value);
      }
    }
  };

  const validateUser = async () => {
    const body = { userName: username.value };
    const { url, options } = NOAUTH_VERIFICA_USER(body);
    const response = await request(url, options);
    if (response.response.status === 422) {
      setError(null);
      return true;
    } else {
      setError('Username inexistente!');
      return false;
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.containerWrapper}>
        <form onSubmit={handleSubmit} className={styles.ContainerForm}>
          <h1 className={styles.title}>Login</h1>
          <p className={styles.subTitle}>Sign in to your account</p>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            {...username}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            {...password}
          />
          <div className={styles.navigate}>
            {loading ? (
              <Button loading={true} disabled>
                Validating
              </Button>
            ) : (
              <Button>Login</Button>
            )}
            <Link className={styles.link} to={'forgot'}>
              Forgot your password?
            </Link>
          </div>
          {error && <Error error={error} />}
        </form>
        <div className={styles.register}>
          <h1 className={styles.title}>Register</h1>
          <p className={styles.subTitle}>Don&apos;t have an account?</p>
          <Link className={styles.linkRegister} to={'register'}>
            Register
          </Link>
        </div>
      </div>
      <div className={styles.log}>
        <Logo />
      </div>
    </section>
  );
}
