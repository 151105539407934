import Head from '../Helper/Head';
import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <>
      <Head title="Not Found" description="Página de not found" />
      <div className={styles.container}>
        <div>
          <h1 className={styles.title}>Error: 404</h1>
          <p>Página não encontrada.</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
