/* eslint-disable no-unused-vars */
import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/InputForm';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import { USER_REGISTER_POST, NOAUTH_VERIFICA_USER } from '../../api';
import { FaLock } from 'react-icons/fa';
import { useEffect, useState, useRef } from 'react';
import Error from '../../components/Helper/Error';
import { Link, useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

const LoginRegister = () => {
  const username = useForm('username');
  const firstname = useForm('name');
  const lastname = useForm('name');
  const email = useForm('email');
  const password = useForm('password');
  const [confirmPass, setConfirmPass] = useState();
  const [message, setMessage] = useState(false);
  const { request, error, setError, loading } = useFetch();
  const inputFocus = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 6500);
    return () => clearInterval(intervalo);
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      username.value === '' ||
      firstname.value === '' ||
      lastname.value === '' ||
      email.value === '' ||
      password.value === '' ||
      confirmPass === '' ||
      email.error !== null ||
      username.error !== null ||
      lastname.error !== null ||
      firstname.error !== null
    ) {
      if (username.error !== null) setError('Preencha um Username válido!');
      if (firstname.error !== null) setError('Preencha um Firstname válido!');
      if (lastname.error !== null) setError('Preencha um Lastname válido!');
      if (email.error !== null) setError('Preencha um Email válido!');
      else setError('Todos os campos são obrigatórios!');
    } else if (confirmPass !== password.value) {
      setError('As senhas não coincidem!');
    } else {
      verifyUsename();
    }
  };

  const verifyUsename = async () => {
    const body = { userName: username.value };
    const { url, options } = NOAUTH_VERIFICA_USER(body);
    const response = await request(url, options);
    if (response.response.status === 422) {
      setError('Username já utilizado!');
    } else {
      pushData();
    }
  };

  const pushData = async () => {
    const { url, options } = USER_REGISTER_POST({
      userName: username.value,
      email: email.value,
      password: password.value,
      firstName: firstname.value,
      lastName: lastname.value,
    });
    const { response } = await request(url, options);
    if (response.ok) {
      setMessage(true);
      username.setValue('');
      firstname.setValue('');
      lastname.setValue('');
      password.setValue('');
      email.setValue('');
      setConfirmPass('');
      inputFocus.current.focus();
      setTimeout(() => {
        navigate('/login');
      }, 4500);
    }
  };

  return (
    <section className={styles.container}>
      {message && (
        <section className={styles.messageContainer}>
          <div>
            <p>Usuário cadastrado com sucesso!</p>
            <br />
            <p>Para efetuar o login o Administrador terá que</p>
            <p>ativar seu cadastro primeiro</p>
          </div>
        </section>
      )}
      <div className={styles.containerWrapper}>
        <form onSubmit={handleSubmit} className={styles.ContainerForm}>
          <Link className={styles.backPage} to={'/login'}>
            <span className={styles.arrow}>
              <BiArrowBack />
            </span>
            Login
          </Link>
          <h1 className={styles.title}>Register</h1>
          <p className={styles.subTitle}>Register about more</p>
          <Input
            useRef={inputFocus}
            type="text"
            name="username"
            placeholder="Username"
            {...username}
          />
          <Input
            type="text"
            name="firstname"
            placeholder="Firstname"
            {...firstname}
          />
          <Input
            type="text"
            name="lastname"
            placeholder="Lastname"
            {...lastname}
          />
          <Input type="email" name="email" placeholder="Email" {...email} />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            {...password}
          />
          <div className={styles.wrapper}>
            <label htmlFor="confirmPass">
              <div className={styles.icons}>
                <FaLock />
              </div>
            </label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                type="password"
                name="confirmPass"
                placeholder="Confirm your password"
                id="confirmPass"
                value={confirmPass}
                onChange={(event) => {
                  setConfirmPass(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.navigate}>
            {loading ? (
              <Button loading={true} disabled>
                Registering
              </Button>
            ) : (
              <Button>Register</Button>
            )}
          </div>
          {error && <Error error={error} />}
        </form>
      </div>

      <div className={styles.log}>
        <Logo />
      </div>
    </section>
  );
};

export default LoginRegister;
