export const API_URL = 'https://hellowdata.azurewebsites.net/';

// AUTH

// Com os dados necessarios é feito o registro do usuario
export function USER_REGISTER_POST(body) {
  return {
    url: API_URL + 'api/v1/auth/registrar',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// Com username e senha válidos é feito o login do usuário
export function USER_LOGIN_POST(body) {
  return {
    url: API_URL + 'api/v1/auth/login',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// NOAUTH

// Verifica se o usuário já é cadastrado ou mão
export function NOAUTH_VERIFICA_USER(body) {
  return {
    url: API_URL + 'api/v1/noauth/verificarusuario',
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// NÃO IMPLEMENTADO AINDA
// Após receber o email com o token para resetar a senha, usuário deve inserir a nova senha, username e o token recebido por email
export function NOAUTH_RECEIVE_TOKEN_RECOVER_PASS(body) {
  return {
    url: API_URL + 'api/v1/noauth/tokenrecuperarsenha',
    options: {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// NÃO IMPLEMENTADO AINDA
// Após receber o email com o token para resetar a senha, usuário deve inserir a nova senha, username e o token recebido por email
export function NOAUTH_RECOVER_PASS(body) {
  return {
    url: API_URL + 'api/v1/noauth/recuperarsenha',
    options: {
      method: 'PATCH',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// USER

// Com um token válido é retornado os dados do usuário
export function USER_GET(token) {
  return {
    url: API_URL + 'api/v1/user/listardados',
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  };
}

// NÃO IMPLEMENTADO AINDA
// Após receber o token em seu email e clicar no link o usuário é redirecionado pasa o site onde deverá preencher o username, token e a nova senha
export function USER_CHANGE_PASS(body, token) {
  return {
    url: API_URL + 'api/v1/noauth/mudarsenha',
    options: {
      method: 'PATCH',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// Com token válido o próprio usuário pode editar seus dados
export function USER_EDIT_PUT(body, token) {
  return {
    url: API_URL + 'api/v1/user/editarusuario',
    options: {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// ADMIN

// Permite que o ADM ative a conta de usuários
export function ADMIN_ACTIVE_USER_PUT(body, token) {
  return {
    url: API_URL + 'api/v1/admin/ativarusuario',
    options: {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// Permite que o ADM edite usuários
export function ADMIN_EDIT_USER_PUT(body, token) {
  return {
    url: API_URL + 'api/v1/admin/editarusuario',
    options: {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// Permite que o ADM exclua usuários
export function ADMIN_DELETE_USER(body, token) {
  return {
    url: API_URL + 'api/v1/admin/removerusuario',
    options: {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}

// Permite que o ADM liste os dados de um determinado usuário a partir do username dele
export function ADMIN_GET_DATA_USER(body, token) {
  return {
    url: API_URL + 'api/v1/admin/listardadosusuario',
    options: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    },
  };
}
