import { useEffect, useState, useRef } from 'react';
import stylesSession from '../Sessions.module.scss';
import styles from './DeleteUser.module.scss';
import useFetch from '../../../../Hooks/useFetch';
import useForm from '../../../../Hooks/useForm';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Error from '../../../../components/Helper/Error';
import { ADMIN_DELETE_USER, NOAUTH_VERIFICA_USER } from '../../../../api';

const DeleteUser = () => {
  const [message, setMessage] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const { loading, error, setError, request } = useFetch();
  const username = useForm();
  const inputFocus = useRef(null);

  async function handleSubmit(event) {
    event.preventDefault();
    if (username.value) {
      const body = { userName: username.value };
      const { url, options } = NOAUTH_VERIFICA_USER(body);
      const response = await request(url, options);
      if (response.response.status === 422) {
        setError(null);
        setConfirm(true);
      } else {
        setError('Usuário inexistente');
      }
    } else if (username.value === '') {
      setError('Preencha um valor.');
    }
  }

  async function handleConfirm() {
    const body = { userName: username.value };
    const token = localStorage.getItem('token');
    const { url, options } = ADMIN_DELETE_USER(body, token);
    const response = await request(url, options);
    if (response.response.status === 500) {
      setConfirm(false);
      setMessage(true);
      username.setValue('');
      inputFocus.current.focus();
    } else {
      setConfirm(false);
    }
  }

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 2700);
    return () => clearInterval(intervalo);
  }, [message]);

  return (
    <section className={stylesSession.containerPai}>
      <div className={stylesSession.container}>
        {message && (
          <section className={styles.messageContainer}>
            <p>Usuário deletado com sucesso!</p>
          </section>
        )}
        {confirm && (
          <section className={styles.confirmContainer}>
            <div className={styles.confirm}>
              <p>
                Realmete deseja deletar{' '}
                <span style={{ fontWeight: 'bold' }}>{username.value}</span>?
              </p>
              <div className={styles.buttons}>
                <button onClick={() => setConfirm(false)}>Não</button>
                <button onClick={handleConfirm}>Confimar</button>
              </div>
            </div>
          </section>
        )}
        <h1 className={styles.title}>Delete User</h1>
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div className={styles.ContainerForm}>
            <Input
              useRef={inputFocus}
              label={'Username'}
              type="text"
              name="username"
              {...username}
            />
            {loading ? (
              <Button loading={true} disabled>
                Checking
              </Button>
            ) : (
              <Button>Delete user</Button>
            )}
            {error && <Error error={error} />}
          </div>
        </form>
      </div>
    </section>
  );
};

export default DeleteUser;
