import React from 'react';
import styles from './InputForm.module.scss';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { MdOutlineGeneratingTokens } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';

const Input = ({
  type,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  useRef
}) => {
  return (
    <div className={styles.wrapper}>
      {' '}
      <label htmlFor={name}>
        <div className={styles.icons}>
          {name.includes('password') ? (
            <FaLock />
          ) : name.includes('token') ? (
            <MdOutlineGeneratingTokens />
          ) : name.includes('email') ? (
            <MdEmail size={22} />
          ) : (
            <FaUserAlt />
          )}
        </div>
      </label>
      <div className={styles.inputContainer}>
        <input
          ref={useRef}
          className={styles.input}
          placeholder={placeholder}
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default Input;
