import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../../UserContext';

const ProtectedRouteAdmin = ({ children }) => {
  const { admin } = useContext(UserContext);
  return admin ? children : <Navigate to="/login" />;
};

export default ProtectedRouteAdmin;
