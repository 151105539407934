import { useNavigate } from 'react-router-dom';
import styles from './Button.module.scss';
import classNames from 'classnames';

const Button = ({ children, to, cursor, loading, ...props }) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    if (to) navigate(to);
    else return null;
  };

  return (
    <button
      {...props}
      onClick={navigateTo}
      style={{ cursor: `${cursor}` }}
      className={classNames({
        [styles.button]: true,
      })}
    >
      <span
        className={classNames({
          [styles.btnLoading]: loading === true,
        })}
      ></span>
      <span className={styles.text}>{children}</span>
    </button>
  );
};

export default Button;
