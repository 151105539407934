import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../../UserContext';

// componete para verificar se o login foi executado, apenas para proteção
// sendo 'children' o componente 'User'
const ProtectedRoute = ({ children }) => {
  const { login } = useContext(UserContext);
  // caso login sejá verdadeiro ele retorna algo, caso não ele navega para login
  return login ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
