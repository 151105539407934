import { useEffect, useState } from 'react';
import stylesSession from '../Sessions.module.scss';
import styles from './ActiveUser.module.scss';
import useFetch from '../../../../Hooks/useFetch';
import useForm from '../../../../Hooks/useForm';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Error from '../../../../components/Helper/Error';
import { ADMIN_ACTIVE_USER_PUT } from '../../../../api';

const ActiveUser = () => {
  const [message, setMessage] = useState(false);
  const { loading, error, request, setError } = useFetch();
  const username = useForm();

  async function handleSubmit(event) {
    event.preventDefault();
    if (username.value) {
      const token = localStorage.getItem('token');
      const body = { userName: username.value };
      const { url, options } = ADMIN_ACTIVE_USER_PUT(body, token);
      const response = await request(url, options);
      if (response.response.ok) {
        setMessage(true);
        username.setValue('');
      }
    } else if (username.value === '') {
      setError('Preencha um valor.');
    }
  }

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 2700);
    return () => clearInterval(intervalo);
  }, [message]);

  return (
    <section className={stylesSession.containerPai}>
      <div className={stylesSession.container}>
        {message && (
          <section className={styles.messageContainer}>
            <p>Usuário ativado com sucesso!</p>
          </section>
        )}
        <h1 className={styles.title}>Active User</h1>
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div className={styles.ContainerForm}>
            <Input
              label={'Username'}
              type="text"
              name="username"
              {...username}
            />
            {loading ? (
              <Button loading={true} disabled>
                Checking
              </Button>
            ) : (
              <Button>Active user</Button>
            )}
            {error && <Error error={error} />}
          </div>
        </form>
      </div>
    </section>
  );
};

export default ActiveUser;
