import Button from '../../components/Forms/Button';
import Head from '../../components/Helper/Head';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';

export default function LoginBackPage() {
  return (
    <section className={styles.container}>
      <Head
        title="Password Changed"
        description="Página de login do site Hellow Data"
      />
      <div className={styles.containerWrapper}>
        <div className={styles.ContainerForm}>
          <h1 className={styles.title}>Your password has changed</h1>
          <div className={styles.navigate}>
            <Button to="/login">Back to Login</Button>
          </div>
        </div>
      </div>
      <div className={styles.log}>
        <Logo />
      </div>
    </section>
  );
}
