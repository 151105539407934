import Head from '../../components/Helper/Head';
import NotFound from '../../components/NotFound/NotFound';
import { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import UserContext from '../../UserContext';
import LoginBackPage from './LoginBackPage';
import LoginResetPassword from './LoginResetPassword';
import LoginForm from './LoginForm';
import LoginReset from './LoginReset';
import styles from './Login.module.scss';
import LoginRegister from './LoginRegister';

const Login = () => {
  const { login } = useContext(UserContext);
  if (login === true) return <Navigate to="/count" />;

  return (
    <section className={styles.routerContainer}>
      <Head title="Login" description="Página de login do site Dogs" />
      <div>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="register" element={<LoginRegister />} />
          <Route path="forgot" element={<LoginReset />} />
          <Route path="resetpassword" element={<LoginResetPassword />} />
          <Route path="resetpassword/:token" element={<LoginResetPassword />} />
          <Route path="backtopage" element={<LoginBackPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </section>
  );
};

export default Login;
