import { useEffect, useState } from 'react';
import Button from '../../../../../components/Forms/Button';
import styles from './Modal.module.scss';
import { IoClose } from 'react-icons/io5';
import { FaTrashAlt } from 'react-icons/fa';
import InvalidPhoto from '../../../../../assets/invalidPhoto.svg';

export const Modal = ({ ativo, imagem, onClose }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [photoIsValid, setPhotoIsValid] = useState(false);
  const [urlImage, setUrlImage] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isMounted) {
      setError(false);
      setUrlImage(imagem.value);
      setPhotoIsValid(true);
    } else {
      setIsMounted(true);
    }
  }, [ativo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    imagem.setValue(urlImage);
    onClose();
  };

  const handleChange = async (e) => {
    try {
      const response = await fetch(e.target.value);
      if (!response.ok) throw new Error('Url inválida');
      // const blob = await response.blob();
      setPhotoIsValid(true);
      setError(false);
    } catch (error) {
      setError(true);
      setPhotoIsValid(false);
      setError(true);
    }
  };

  const deleteImage = async () => {
    setPhotoIsValid(true);
    setError(false);
  };

  const handleClose = () => {
    setUrlImage(imagem.value);
    onClose();
  };

  return (
    <section className={styles.modalContainer}>
      <IoClose className={styles.fechar} onClick={handleClose} />
      <div className={styles.modal}>
        <form onSubmit={handleSubmit} className={styles.containerForm}>
          <div className={styles.photoConteiner}>
            <div className={styles.photo}>
              <img
                src={photoIsValid ? urlImage : InvalidPhoto}
                className={styles.imagem}
                alt="Minha imagem em inicial"
              />
            </div>
          </div>
          <div className={styles.editPhoto}>
            <div>
              <label htmlFor="URL">URL image</label>
              <input
                type="text"
                name="URL"
                className={styles.input}
                value={urlImage}
                onChange={(e) => {
                  setUrlImage(e.target.value);
                  handleChange(e);
                }}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            {error ? (
              <Button disabled cursor={'not-allowed'}>
                Change
              </Button>
            ) : (
              <Button cursor={'pointer'}>Change</Button>
            )}
            <div
              className={styles.excluir}
              onClick={() => {
                setUrlImage(
                  'https://res.cloudinary.com/halfgenius/image/upload/v1681683009/hd/5245947721537356155.svg',
                );
                deleteImage();
              }}
            >
              <FaTrashAlt size={20} className={styles.icon} />
              <p>Excluir</p>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
