import { useCallback, useState } from 'react';

const useFetch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (url, options) => {
    let response;
    let json;
    try {
      setError(null);
      setLoading(true);
      response = await fetch(url, options);
      if (response.status === 404 && response.url.includes('removerusuario'))
        throw new Error('Usuário inativo!');
      if (response.status === 400 && response.url.includes('ativarusuario'))
        throw new Error('Usuário inexistente ou já ativado!');
      if (
        response.status === 400 &&
        response.url.includes('listardadosusuario')
      )
        throw new Error('Usuário inexistente.');
      if (response.status === 422 && response.url.includes('verificarusuario'))
        throw new Error('');
      if (response.status === 400 && response.url.includes('editarusuario'))
        throw new Error('Dados inválidos! Não é permitido acentuação ou números!');
      if (response.status === 400 && response.url.includes('registrar'))
        throw new Error('Email já cadastrado!');
      if (response.status === 500 && response.url.includes('removerusuario'))
        throw new Error('');
      if (response.status === 400 && response.url.includes('removerusuario'))
        throw new Error('Ocorreu um erro, tente novamente!');
      if (
        response.status === 400 &&
        response.url.includes('tokenrecuperarsenha')
      )
        throw new Error('Usuário inexistente!');
      if (!response.ok) throw new Error(`Error: ${json.message}`);
      if (response.ok) json = await response.json();
    } catch (err) {
      json = null;
      setError(err.message);
    } finally {
      if (response.ok) setError(null);
      setData(json);
      setLoading(false);
    }
    return { response, json };
  }, []);

  return {
    data,
    error,
    setError,
    loading,
    setLoading,
    request,
  };
};

export default useFetch;
