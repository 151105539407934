import { useNavigate } from 'react-router-dom';
import styles from './ButtonSide.module.scss';
import { useContext } from 'react';
import UserContext from '../../../UserContext';

export const Button = ({
  btnContainer,
  show,
  children,
  to,
  svg,
  btnClass,
  iconClass,
}) => {
  const navigate = useNavigate();
  const { userLogOut } = useContext(UserContext);

  const navigateTo = () => {
    if (to) navigate(to);
  };

  return (
    <div
      onClick={to ? navigateTo : userLogOut}
      className={`${styles[btnContainer]}`}
      style={{ backgroundColor: `${show ? '#2F303A' : 'transparent'}` }}
    >
      <span className={`${styles[iconClass]}`}>{svg}</span>
      {show && (
        <button className={`${styles[btnClass]}`}>
          <span className={styles.text}>{children}</span>
        </button>
      )}
    </div>
  );
};

export default Button;
