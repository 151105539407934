import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import stylesSession from '../Sessions.module.scss';
import styles from './MyProfile.module.scss';
import UserContext from '../../../../UserContext';
import Input from '../../../../components/Forms/Input';
import useForm from '../../../../Hooks/useForm';
import Button from '../../../../components/Forms/Button';
import { ADMIN_ACTIVE_USER_PUT, USER_EDIT_PUT } from '../../../../api';
import useFetch from '../../../../Hooks/useFetch';
import Error from '../../../../components/Helper/Error';
import { FaPencilAlt } from 'react-icons/fa';
import { TbMessages } from 'react-icons/tb';
import { Modal } from './Modal';

const MyProfile = () => {
  const firstname = useForm('name');
  const lastname = useForm('name');
  const email = useForm('email');
  let imagem = useForm();
  const modalRef = useRef();
  const { loading, error, request, setError } = useFetch();
  const { data, setData } = useContext(UserContext);
  const [ativo, setAtivo] = useState(false);
  const [notice, setNotice] = useState(true);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setNotice(false);
    }, 5000);
    return () => clearInterval(intervalo);
  }, []);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 2700);
    return () => clearInterval(intervalo);
  }, [message]);

  useEffect(() => {
    async function dataImage() {
      if (data) {
        email.setValue(data.email);
        firstname.setValue(data.firstName);
        lastname.setValue(data.lastName);
        imagem.setValue(data.profilePictureUrl);
      }
    }
    dataImage();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    const token = window.localStorage.getItem('token');
    if (
      firstname.value === '' ||
      lastname.value === '' ||
      email.value === '' ||
      email.error !== null ||
      lastname.error !== null ||
      firstname.error !== null
    ) {
      if (email.error !== null) setError('Preencha um email válido');
      else if (firstname.error !== null)
        setError('Preencha um Firstname válido!');
      else if (lastname.error !== null) {
        setError('Preencha um Lastname válido!');
      } else setError('Todos os campos são obrigatórios!');
    } else {
      const body = {
        userName: data.userName,
        email: email.value,
        firstName: firstname.value,
        lastName: lastname.value,
        profilePictureUrl: imagem.value,
      };
      const { url, options } = USER_EDIT_PUT(body, token);
      const response = await request(url, options);
      if (response.response.ok) {
        setData({
          email: email.value,
          emailConfirmed: true,
          firstName: firstname.value,
          lastName: lastname.value,
          profilePictureUrl: imagem.value,
          userName: data.userName,
        });
        activeUser(token);
      }
    }
  }

  async function activeUser(token) {
    if (token && data) {
      const body = { userName: data.userName };
      const { url, options } = ADMIN_ACTIVE_USER_PUT(body, token);
      const response = await request(url, options);
      if (response.response.ok) {
        setMessage(true);
      }
    }
  }

  return (
    <div className={stylesSession.containerPai}>
      <section
        id="modal"
        onClick={(event) =>
          event.target.id === 'modal' ? setAtivo(false) : ''
        }
      >
        <div
          id="modal"
          ref={modalRef}
          className={classNames({
            [styles.modalContainer]: ativo === false,
            [styles['ativo']]: ativo === true,
          })}
          onClick={(e) => {
            if (e.target.id === 'modal') setAtivo(false);
          }}
        >
          <div className={styles.wrapperModal}>
            <Modal
              ativo={ativo}
              imagem={imagem}
              loading={loading}
              onClose={() => setAtivo(false)}
            />
          </div>
        </div>
      </section>
      <section className={stylesSession.container}>
        {message && (
          <section className={styles.messageContainer}>
            <p>Edição feita com sucesso!</p>
          </section>
        )}
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div className={styles.photoConteiner}>
            <div className={styles.photo}>
              {imagem.value && (
                <img
                  onClick={() => setAtivo(true)}
                  src={imagem.value}
                  className={styles.imagem}
                  alt="Minha imagem em inicial"
                />
              )}
            </div>
            <div onClick={() => setAtivo(true)} className={styles.editPhoto}>
              Edit photo <FaPencilAlt style={{ color: '007C71' }} />
            </div>
          </div>
          <div className={styles.ContainerForm}>
            <Input label={'Edit email '} type="text" name="email" {...email} />
            <Input
              label={'Edit firstname'}
              type="text"
              name="firstname"
              {...firstname}
            />
            <Input
              label={'Edit lastname'}
              type="text"
              name="lastname"
              {...lastname}
            />
            {loading ? (
              <Button loading={true} disabled>
                Updating
              </Button>
            ) : (
              <Button>Update</Button>
            )}
            {error && <Error error={error} />}
          </div>
        </form>
        <div
          className={styles.mensagem}
          onMouseOver={() => setNotice(true)}
          onMouseOut={() => setNotice(false)}
        >
          <TbMessages className={styles.icon} style={{ color: '#007C71' }} />
          {notice && (
            <div className={styles.orientaceos}>
              <p>Orientações</p>
              <p>
                O tamanho mínimo para uma foto de perfil no Instagram é 110 x
                100 pixels, porém o tamanho recomendado é 152 x 152 pixels.
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default MyProfile;
