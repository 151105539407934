export default function Error({ error }) {
  if (!error) return null;
  return (
    <p
      style={{
        fontSize: '12px',
        fontWeight: '500',
        marginTop: '1rem',
        color: '#f31',
      }}
    >
      {error}
    </p>
  );
}
