import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './components/NotFound/NotFound';
import Login from './pages/Login/Login';
import User from './pages/User/User';
import PaginaPadrao from './pages/PaginaPadrao';
import ProtectedRoute from './components/Helper/ProtectedRoute';
import { UserStorage } from './UserContext';
import Admin from './pages/Admin/Admin';
import ProtectedRouteAdmin from './components/Helper/ProtectedRouteAdmin';

export default function AppRouter() {
  return (
    <main className="AppBody">
      <BrowserRouter>
        <UserStorage>
          <Routes>
            <Route path="/" element={<PaginaPadrao />} />
            <Route path="login/*" element={<Login />} />
            <Route
              path="count/*"
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path="administration/*"
              element={
                <ProtectedRouteAdmin>
                  <Admin />
                </ProtectedRouteAdmin>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserStorage>
      </BrowserRouter>
    </main>
  );
}
