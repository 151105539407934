import styles from '../Sessions.module.scss';

const MyCompany = () => {
  return (
    <section className={styles.containerPai}>
      {' '}
      <div className={styles.container}>MyCompany</div>
    </section>
  );
};

export default MyCompany;
