import React from 'react';
import styles from './Input.module.scss';

const InputProfile = ({
  type,
  name,
  value,
  onChange,
  onBlur,
  label,
  error,
  useRef,
}) => {
  return (
    <div className={styles.wrapper}>
      {' '}
      <label htmlFor={name}>{label}</label>
      <input
        ref={useRef}
        className={styles.input}
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default InputProfile;
