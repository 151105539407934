import { ReactComponent as Logo } from '../../../assets/icons/helloData.svg';
import Button from '../ButtonSideBar';
import styles from '../SideBar.module.scss';
import { TiUserDelete } from 'react-icons/ti';
import { FaUserCheck, FaUserEdit } from 'react-icons/fa';
import { GoSignOut } from 'react-icons/go';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import classNames from 'classnames';

export const AdminSidebar = ({ sideOpen, setSideOpen }) => {
  return (
    <aside className={styles.sidebar}>
      <div className={styles.wrapper}>
        <div
          className={classNames({
            [styles.logoContainerOpen]: true,
            [styles.logoContainerClose]: sideOpen === false,
          })}
        >
          <div
            className={styles.btnMenu}
            onClick={() => {
              setSideOpen(!sideOpen);
            }}
          >
            <HiOutlineMenuAlt3 size={25} />
          </div>
          <div className={styles.log}>
            <Logo />
            {sideOpen && <p className={styles.title}>Administration</p>}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.wrapperBtn}>
            <Button
              show={sideOpen}
              btnContainer="buttonContainer"
              btnClass="button"
              iconClass="icon"
              to="/administration/activeuser"
              svg={<FaUserCheck />}
            >
              Active user
            </Button>
            <Button
              show={sideOpen}
              btnContainer="buttonContainer"
              btnClass="button"
              iconClass="icon"
              to="/administration/deleteuser"
              svg={<TiUserDelete />}
            >
              Delete user
            </Button>
            <Button
              show={sideOpen}
              btnContainer="buttonContainer"
              btnClass="button"
              iconClass="icon"
              to="/administration/edituser"
              svg={<FaUserEdit />}
            >
              Edit user
            </Button>
          </div>
          <div className={styles.settings}>
            <Button
              btnContainer="buttonContainer2"
              show={sideOpen}
              btnClass="button2"
              iconClass="icon2"
              svg={<GoSignOut />}
            >
              Log out
            </Button>
            <div className={styles.space}></div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AdminSidebar;
