import {useNavigate } from 'react-router-dom';
import {useEffect} from 'react';

export default function PaginaPadrao() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <></>
  );
}
