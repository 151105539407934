/* eslint-disable no-unused-vars */
import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/InputForm';
import Head from '../../components/Helper/Head';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';
import useForm from '../../Hooks/useForm';
import Error from '../../components/Helper/Error';
import useFetch from '../../Hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { NOAUTH_RECOVER_PASS, NOAUTH_VERIFICA_USER } from '../../api';
import { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';

const LoginResetPassword = () => {
  const username = useForm();
  const password = useForm('password');
  const [confirmPass, setConfirmPass] = useState();
  const { loading, error, request, setError } = useFetch();
  const navigate = useNavigate();
  const [token, setToken] = useState();

  useEffect(() => {
    const url = new URL(window.location.href);
    const parametros = new URLSearchParams(url.search);
    setToken(parametros.get('token'));
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    if (username.value === '' || password.value === '' || confirmPass === '') {
      setError('Todos os campos são obrigatórios!');
    } else if (password.error !== null) {
      setError('A senha tem que ter no mínimo 6 caracteres!');
    } else if (!(confirmPass === password.value)) {
      setError('As senhas não coincide!');
    } else {
      verifyUsename();
    }
  }

  const verifyUsename = async () => {
    const body = { userName: username.value };
    const { url, options } = NOAUTH_VERIFICA_USER(body);
    const response = await request(url, options);
    if (response.response.status === 422) {
      pushData();
    } else {
      setError('Nenhum usuário encontrado com este username!');
    }
  };

  const pushData = async () => {
    if (token) {
      const { url, options } = NOAUTH_RECOVER_PASS({
        userName: username.value,
        token: token,
        password: password.value,
      });
      const response = await request(url, options);
      if (response.response.ok) {
        setTimeout(() => {
          navigate('/login/backtopage');
        }, 1500);
      }
    }
  };

  return (
    <section className={styles.container}>
      <Head
        title="Create new password"
        description="Página de criar nova senha do site Hellow Data"
      />
      <div className={styles.containerWrapper}>
        <form onSubmit={handleSubmit} className={styles.ContainerForm}>
          <h1 className={styles.title}>Create New Password</h1>
          <p className={styles.subTitle}>Create new password to your account</p>

          <Input
            type="text"
            name="username"
            placeholder="Username"
            {...username}
          />
          <Input
            type="password"
            name="password"
            placeholder="Create new password"
            {...password}
          />
          <div className={styles.wrapper}>
            <label htmlFor="confirmPass">
              <div className={styles.icons}>
                <FaLock />
              </div>
            </label>
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                type="password"
                name="confirmPass"
                placeholder="Confirm your password"
                id="confirmPass"
                value={confirmPass}
                onChange={(event) => {
                  setConfirmPass(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.navigate}>
            {loading ? (
              <Button loading={true} disabled>
                Confirming
              </Button>
            ) : (
              <Button>Confirm</Button>
            )}
          </div>
          {error && <Error error={error} />}
        </form>
      </div>
      <div className={styles.log}>
        <Logo />
      </div>
    </section>
  );
};

export default LoginResetPassword;
