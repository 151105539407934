import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/InputForm';
import Head from '../../components/Helper/Head';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';
import Error from '../../components/Helper/Error';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import { NOAUTH_RECEIVE_TOKEN_RECOVER_PASS } from '../../api';
import { useEffect, useState } from 'react';

const LoginReset = () => {
  const username = useForm();
  const { loading, error, request } = useFetch();
  const [message, setMessage] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = NOAUTH_RECEIVE_TOKEN_RECOVER_PASS({
      userName: username.value,
    });
    const response = await request(url, options);
    if (response.response.ok) {
      setMessage(true);
      username.setValue('');
    }
  }

  useEffect(() => {
    const intervalo = setInterval(() => {
      setMessage(false);
    }, 4000);
    return () => clearInterval(intervalo);
  }, [message]);

  return (
    <section className={styles.container}>
      {message && (
        <section className={styles.messageContainer}>
          <div>
            <p>Um token foi enviado com sucesso para seu email!</p>
          </div>
        </section>
      )}
      <Head
        title="Reset Password"
        description="Página de inserir nova senha do site Hellow Data"
      />
      <div className={styles.containerWrapper}>
        <form onSubmit={handleSubmit} className={styles.ContainerForm}>
          <h1 className={styles.title}>Reset Your Password</h1>
          <p className={styles.subTitle}>
            Enter your username to recive you password reset code.
          </p>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            {...username}
          />
          <div className={styles.navigate}>
            {loading ? (
              <Button loading={true} disabled>
                Sending
              </Button>
            ) : (
              <Button>Send</Button>
            )}
          </div>
          {error && <Error error={error} />}
        </form>
      </div>
      <div className={styles.log}>
        <Logo />
      </div>
    </section>
  );
};

export default LoginReset;
